import slick from 'slick-carousel'

export default class Slick {
  constructor() {
    var $status = $('.Service-Count');
    var $slickElement = $('.Service-Slider-Box');

    $slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      var i = (currentSlide ? currentSlide : 0) + 1;
      $status.text(i + ' / ' + slick.slideCount);
    });

    $('.Service-Slider-Box').slick({
      slidesToShow: 2,
      slidesToScroll: 1,
      variableWidth: true,
      autoplay: true,
      autoplaySpeed: 3000,
      arrows: true,
      responsive: [
        {
          breakpoint: 1024,     // 600〜1023px
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,      // 480〜599px
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,      // 〜479px
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }],
    });
  }
}