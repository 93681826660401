import Aos from './class/Aos';
import Slick from './class/Slick';
import Animation from './class/Animation';

export default class Main {
    constructor() {
        new Aos()
        new Slick()
        new Animation()
      }
  }

  window.addEventListener('DOMContentLoaded',() => {
    new Main()
  });