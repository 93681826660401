export default class Animation {
  constructor() {
    $(window).on('load', function() {
      $('html').css("opacity", "1");
    });

    $(function () {
      if (navigator.cookieEnabled) {
        var count = 1;
        if (document.cookie.indexOf("count=") == -1) {
          $('.Cookie').children("button").on('click', function () {
            $('.Cookie').fadeOut();
            document.cookie = "count=1; max-age=86400";
          });
        } else {
          $('.Cookie').hide();
        }
      }
    });
    
    $('a[href^="#"]').click(function(){
      var speed = 500;
      var href= $(this).attr("href");
      var target = $(href == "#" || href == "" ? 'html' : href);
      var position = target.offset().top;
      $("html, body").animate({scrollTop:position}, speed, "swing");
      return false;
    });

    $(".List-Sp-Button-List").hide();
    $('.List-Sp-Button').click(
      function () {
        if ($(this).hasClass('Open')) {
          $(this).addClass('Close');
          $(this).removeClass('Open --arrow-open');
          $(".List-Sp-Button-List").stop(true, true).fadeOut(200);
        } else {
          $(this).addClass('Open --arrow-open');
          $(this).removeClass('Close');
          $(".List-Sp-Button-List").stop(true, true).fadeIn(200);
        }
      }
    );


    $(".List-Sp").hide();
    $('.Button-SP').click(
      function () {
        if ($(this).hasClass('Open')) {
          $(this).addClass('Close');
          $(this).removeClass('Open');
          $(".List-Sp").stop(true, true).fadeOut(200);
          $(".Button-SP-LineTop").css("transform", "translateY(-5px)");
          $(".Button-SP-LineMiddle").css("opacity", "1");
          $(".Button-SP-LineBottom").css("transform", "translateY(5px)");
          $("html").css("overflow", "");
        } else {
          $(this).addClass('Open');
          $(this).removeClass('Close');
          $(".List-Sp").stop(true, true).fadeIn(200);
          $(".Button-SP-LineTop").css("transform", "rotate(45deg)");
          $(".Button-SP-LineMiddle").css("opacity", "0");
          $(".Button-SP-LineBottom").css("transform", "rotate(-45deg)");
          $("html").css("overflow", "hidden");
        }
      }
    );
    $('.News-List-Viewall').hover(
      function () {
        $(this).css('background-color', 'white');
        $(this).css('color', 'black');
      },
      function () {
        $(this).css('background-color', 'black');
        $(this).css('color', 'white');
      }
    );
    $(".List-Box").children('.List-Hover').stop(true, true).hide();
    $('.List-Box').stop().hover(function () {
      $(this).children('.List-Hover').stop(true, true).delay(200).slideDown(200);
    },
      function () {
        $(this).children('.List-Hover').stop(true, true).delay(200).slideUp(200);
      }
    );

    $('header .--deco').stop().hover(function () {
      $(this).stop(true, true).delay(200).addClass('--deco-open');
    },
      function () {
        $(this).stop(true, true).delay(200).removeClass('--deco-open');
      }
    );

    $('.Button-PC').hover(
      function () {
        $('.Button-PC').css('background-color', 'white');
        $('.Button-PC a').css('color', '#d2a000');
      },
      function () {
        $('.Button-PC').css('background-color', '#d2a000');
        $('.Button-PC a').css('color', 'white');
      }
    );
    $('.Service-Slider-Box-Con').hover(
      function () {
        $(this).find('img').css({
          'transform': 'scale(1.1)',
          'transition': 'all 1s cubic-bezier(0, 0, 0.2, 1) 0s'
        });
      },
      function () {
        $(this).find('img').css({
          'transform': 'scale(1)',
        });
      }
    );
    $('.Contact-Link').hover(
      function () {
        $(this).css('background-color', 'white');
        $(this).css('color', '#d2a000');
      },
      function () {
        $(this).css('background-color', '#d2a000');
        $(this).css('color', 'white');
      }
    );
    $('.Logo').hover(
      function () {
        $(this).css({
          'opacity': '0.8',
          'transition': 'all .25s cubic-bezier(0, 0, 0.2, 1) 0s'
        });
      },
      function () {
        $(this).css({
          'opacity': '1',
          'transition': 'all .25s cubic-bezier(0, 0, 0.2, 1) 0s'
        });
      }
    );
    $('.Link').find('a').hover(
      function () {
        $(this).css({
          'opacity': '0.7',
          'transition': 'all .25s cubic-bezier(0, 0, 0.2, 1) 0s'
        });
      },
      function () {
        $(this).css({
          'opacity': '1',
          'transition': 'all .25s cubic-bezier(0, 0, 0.2, 1) 0s'
        });
      }
    );
  }
}